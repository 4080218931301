<template>
  <div class="manage-prompt">
    <div class="top">
      <el-button type="warning" @click="addPromptClick">添加指令模板</el-button>
    </div>

    <el-table
      ref="tableRef"
      :data="promptData"
      style="width: 100%; margin-top: 10px"
      height="82vh"
      v-loading="tableLoading"
      @selection-change="handleSelectionChange"
    >
      <!-- <el-table-column type="selection" width="55" /> -->
      <el-table-column
        prop="prompt"
        label="prompt">
      </el-table-column>
      <el-table-column
        prop="function"
        label="功能"
        :formatter="fnFormatter"
        width="130">
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100">
        <template #default="scope">
          <el-button
            link
            type="primary"
            size="small"
            @click.prevent="editPrompt(scope.$index, scope.row)"
          >
            编辑
          </el-button>
          <el-button
            link
            type="danger"
            size="small"
            @click.prevent="deleteRow(scope.$index, scope.row)"
            :loading="submitLoading"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <Dialog v-model="isPromptDialogShow">
      <div class="prompt-box">
        <div class="title">{{ dialogTitle }}</div>

        <div class="form-item">
          功能：
          <el-select :disabled="type === 'edit'" v-model="form.function" placeholder="请选择">
            <el-option
              key="1"
              label="法律法规查询"
              value="consult_query_prompt">
            </el-option>
            <el-option
              key="2"
              label="合同审查"
              value="contract_review_prompt">
            </el-option>
            <el-option
              key="3"
              label="文书草拟"
              value="contract_draft_prompt">
            </el-option>
            <el-option
              key="4"
              label="优化方案"
              value="contract_optimize_prompt">
            </el-option>
          </el-select>
        </div>
        <div class="form-item">
          指令：
          <!-- <input class="input" type="text" placeholder="请输入指令名称" v-model="requirement"> -->
          <textarea class="textarea" placeholder="请输入" name="prompt" id="prompt" v-model="form.prompt"></textarea>
        </div>

        <div class="btn" @click="promptSubmit" v-loading="submitLoading">确定</div>
      </div>
    </Dialog>
  </div>
</template>

<script setup>
import { ref, watch, computed, onMounted } from 'vue'

import Dialog from '../../components/dialog.vue'

import { getConfiguration, setConfiguration } from '../../request/index'

import { ElMessageBox } from 'element-plus'
import { createToaster } from "@meforma/vue-toaster";
const toaster = createToaster({
  position: "top"
});

let configData = {}
const promptData = ref([]),
  tableLoading = ref(false)
const getPromptData = () => {
  tableLoading.value = true
  getConfiguration().then(({ data }) => {
    configData = data.settings
    promptData.value = data.settings.prompts
    console.log(promptData.value)
  }).finally(() => {
    tableLoading.value = false
  })
}
const functionMap = {
  consult_query_prompt: '法律法规查询',
  contract_review_prompt: '合同审查',
  contract_draft_prompt: '文书草拟',
  contract_optimize_prompt: '优化方案'
}
const fnFormatter = (row) => {
  return functionMap[row.function]
}

const handleSelectionChange = () => {

}

const isPromptDialogShow = ref(false),
  dialogTitle = ref(''),
  promptValue = ref(''),
  submitLoading = ref(false),
  form = ref({
    prompt: '',
    function: ''
  })
let type = ref('add'),
  curIndex = null
const addPromptClick = () => {
  isPromptDialogShow.value = true
  dialogTitle.value = '添加指令'
  type.value = 'add'
  // promptValue.value = '';
  form.value.prompt = ''
  form.value.function = ''
}
const editPrompt = (i, v) => {
  console.log(i,v);
  isPromptDialogShow.value = true
  dialogTitle.value = '修改指令'
  curIndex = i
  type.value = 'edit'
  // promptValue.value = v.prompt
  form.value.prompt = v.prompt
  form.value.function = v.function
}
const promptSubmit = () => {
  if (!form.value.function) {
    toaster.warning('请选择功能')
    return
  }
  if (!form.value.prompt) {
    toaster.warning('请输入指令')
    return
  }

  const data = JSON.parse(JSON.stringify(promptData.value))
  if (type.value === 'add') {
    const functions = data.map(v => v.function)
    if (functions.includes(form.value.function)) {
      toaster.warning(`已存在 ${functionMap[form.value.function]} 指令`)
      return
    }
    data.push(form.value)
  } else if (type.value === 'edit') {
    data[curIndex].prompt = form.value.prompt
    data[curIndex].function = form.value.function
  }

  // console.log(promptData.value)
  // console.log(data)
  configData.prompts = data
  console.log('传参：', {
    settings: configData
  })

  submitLoading.value = true
  setConfiguration({
    cmd: "setting",
    settings: configData
  }).then(res => {
    isPromptDialogShow.value = false
    getPromptData()
  }).finally(() => {
    submitLoading.value = false
  })
}
const deleteRow = (i, v) => {
  console.log(i,v);
  ElMessageBox.confirm(
    '确定删除吗？',
    '警示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    const data = JSON.parse(JSON.stringify(promptData.value))
    data.splice(i, 1)
    submitLoading.value = true
    setConfiguration({
      cmd: "setting",
      settings: {
        prompts: data
      }
    }).then(res => {
      isPromptDialogShow.value = false
      getPromptData()
    }).finally(() => {
      submitLoading.value = false
    })
  })
}

onMounted(() => {
  getPromptData()
})
</script>

<style lang="less" scoped>
.manage-prompt {
  padding: 2%;

  .top {
    display: flex;
    justify-content: space-between;
  }


  .prompt-box {
    width: 600px;
    padding: 0 20px;
    box-sizing: border-box;

    .title {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      color: #1d1e22;
      padding: 10px 0 20px;
      border-bottom: 1px solid rgba(213,213,213,0.35);
      margin-bottom: 36px;
    }
    .form-item {
      margin-top: 20px;
      font-size: 14px;

      .textarea {
        display: block;
        width: 100%;
        height: 300px;
        margin-top: 6px;
        border: 1px solid #d5d5d5;
        border-radius: 6px;
        outline: none;
        padding: 6px;
        box-sizing: border-box;
      }
    }

    .btn {
      width: 100%;
      height: 40px;
      line-height: 40px;
      text-align: center;
      background: #f19135;
      color: #fff;
      box-sizing: border-box;
      margin: 36px 0 10px 0;
      padding: 0 4%;
      border-radius: 6px;
    }
  }
}
</style>